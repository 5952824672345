.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 1rem;
  width: 12rem;
}

.text {
  letter-spacing: 0.078em;
  font-family: var(--default-font);
  font-style: normal;
  font-weight: var(--font-weight-thin);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  color: var(--color-blue);
}
