.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (--viewport-m) {
    flex-direction: row;
  }
}

.main {
  overflow-y: auto;
  flex: 1;
  padding: 1.6rem;

  @media (--viewport-m) {
    padding: 4.8rem;
  }
}

.noScroll {
  position: fixed;

  @media (--viewport-m) {
    position: static;
  }
}
