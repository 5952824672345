.menuItem {
  font-size: var(--font-size-m);
  display: flex;
  align-items: center;
  list-style: none;
  padding: 1.6rem 1.6rem 1.6rem 3.2rem;
  margin: 0;
  width: 100%;
  border: none;
  background: none;
  color: var(--color-blue-100);
  line-height: var(--line-height-l);
  cursor: pointer;
  text-align: left;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.6rem;
  }

  & .chevron {
    margin-right: 0;
    margin-left: auto;
  }
}

.users {
  display: none;
  margin: 0;
  margin-top: 10.1rem;
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  flex: 1;
  background-color: var(--color-lightblue);

  @media (--viewport-m) {
    height: auto;
    width: auto;
    position: absolute;
    list-style: none;
    left: 100%;
    top: auto;
    background-color: var(--color-white);
    box-shadow: 0 0.4rem 0.8rem rgb(24 37 78 / 10%);
    margin-top: 10.1rem;
  }

  &.visible {
    display: block;
  }

  & li {
    min-width: 24rem;
    vertical-align: middle;
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;
    cursor: pointer;
    font-size: var(--font-size-m);
    line-height: var(--line-height-l);
    color: var(--color-darkblue-100);

    &.selected {
      color: var(--color-black);
      background-color: var(--color-grey);
    }

    &:hover {
      color: var(--color-black);
      background-color: var(--color-lightblue);
    }
  }
}
