.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-lightblue);
  padding: 4rem 1.6rem 0.8rem;
  z-index: 2;

  @media (--viewport-m) {
    width: 24rem;
    justify-content: center;
    position: static;
  }
}

.icon {
  right: 0;
  bottom: 0;
  color: var(--color-blue);
  border: none;
  background: none;

  @media (--viewport-m) {
    display: none;
  }

  & svg {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.menu {
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-lightblue);
  left: 0;
  color: var(--color-blue);
  padding: 0;
  z-index: 1;

  @media (--viewport-m) {
    width: 24rem;
    position: static;
  }
}

.menuItems {
  margin-top: 10.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  flex: 1;

  @media (--viewport-m) {
    margin-top: 0;
  }
}

.menuItemsSection {
  padding: 0;
}

.menuItem {
  font-size: var(--font-size-m);
  display: flex;
  list-style: none;
  padding: 1.6rem 1.6rem 1.6rem 3.2rem;
  margin: 0;
  width: 100%;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.6rem;
  }
}

.menuItemsSectionBottom {
  margin-top: auto;
  padding: 0;
}

.hidden {
  display: none;

  @media (--viewport-m) {
    display: flex;
  }
}
