.loader {
  animation: rotation 1s linear infinite;
  border-radius: 50%;
  border: 0.2rem solid var(--color-darkblue-100);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  border-bottom-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.inverted {
  border-color: var(--color-white);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  border-bottom-color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
